$icomoon-font-family: "pandora" !default;
$icomoon-font-path: "/assets/fonts/pandora" !default;

$icon-add: "\e900";
$icon-add_info: "\e901";
$icon-admin: "\e902";
$icon-admin_add: "\e903";
$icon-arrow_down: "\e904";
$icon-arrow_left: "\e905";
$icon-arrow_right: "\e906";
$icon-arrow_up: "\e907";
$icon-articles: "\e908";
$icon-articles_2: "\e909";
$icon-articles_add: "\e90a";
$icon-back: "\e90b";
$icon-cached: "\e944";
$icon-clients: "\e90c";
$icon-clients_add: "\e90d";
$icon-close: "\e90e";
$icon-code: "\e94d";
$icon-code_off: "\e94e";
$icon-copy: "\e93b";
$icon-credit_card: "\e94f";
$icon-credit_card_off: "\e950";
$icon-delete: "\e90f";
$icon-deya: "\e910";
$icon-done: "\e945";
$icon-download: "\e92e";
$icon-drag_handle: "\e93e";
$icon-edit: "\e911";
$icon-exchange: "\e912";
$icon-existing: "\e913";
$icon-filter: "\e942";
$icon-finish: "\e914";
$icon-fullscreen: "\e915";
$icon-global: "\e916";
$icon-help: "\e93f";
$icon-help_o: "\e940";
$icon-historic: "\e917";
$icon-import_export: "\e941";
$icon-in_progress: "\e918";
$icon-info: "\e919";
$icon-input: "\e91a";
$icon-layers: "\e951";
$icon-layers-o: "\e955";
$icon-layers_clear: "\e952";
$icon-layers_clear-o: "\e956";
$icon-link: "\e91b";
$icon-link_off: "\e91c";
$icon-list: "\e91d";
$icon-location: "\e91e";
$icon-lock: "\e94a";
$icon-lock_open: "\e94b";
$icon-maintenance: "\e91f";
$icon-maximize: "\e92f";
$icon-merge: "\e93a";
$icon-minimize: "\e930";
$icon-more: "\e920";
$icon-one: "\e935";
$icon-option: "\e921";
$icon-output: "\e922";
$icon-pageview: "\e947";
$icon-picture: "\e923";
$icon-power: "\e93d";
$icon-preview: "\e948";
$icon-project: "\e924";
$icon-project_add: "\e925";
$icon-published_with_changes: "\e946";
$icon-refresh: "\e93c";
$icon-report: "\e954";
$icon-report-stat: "\e953";
$icon-review: "\e936";
$icon-save: "\e926";
$icon-search: "\e927";
$icon-server: "\e934";
$icon-server-outline: "\e939";
$icon-settings: "\e938";
$icon-share: "\e931";
$icon-storage: "\e933";
$icon-syno: "\e928";
$icon-tag: "\e929";
$icon-to_do_list: "\e92a";
$icon-trophy: "\e937";
$icon-undo: "\e957";
$icon-verified: "\e92b";
$icon-view_list: "\e92c";
$icon-view_module: "\e92d";
$icon-visibility: "\e949";
$icon-warning: "\e94c";
$icon-zoom_in: "\e932";
$icon-zoom_out: "\e943";

