@import "src/assets/css/variable/variables";


.mat-mdc-form-field.mat-mdc-form-field-type-mat-select.status-toggle {
	min-width: 6rem;

	.mat-form-field-label {
		display: none;
	}

	.mat-select-placeholder, .mat-form-field-label.mat-empty {
		color: $app-white;
		font-weight: bold;
		display: block;
		padding-left: 0.75rem;
	}

	.mat-mdc-form-field-infix {
		border: none;
		border-radius: 15px;
		background-color: $app-grey;
		padding: .25rem .5rem 0.25rem 0.75rem;
		min-height: 30px;
	}

	&.blue .mat-mdc-form-field-infix {
		background: $app-blue;
	}

	&.green .mat-mdc-form-field-infix {
		background: $app-green;
	}

	&.red .mat-mdc-form-field-infix {
		background: $app-red;
	}

	input, .mat-mdc-select-value {
		color: $app-white;
		font-weight: bold;
		font-size: 15px;
	}

	.mat-mdc-select-arrow {
		color: $app-white;
	}

	&.green {
		.mat-mdc-form-field-infix {
			background-color: $app-green;
		}
	}

	&.red {
		.mat-mdc-form-field-infix {
			background-color: $app-red;
		}
	}
}
