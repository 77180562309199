button,
button.mat-mdc-button{
	cursor: pointer;
	padding: .5rem 1rem;
	font-size: 14px;
	font-weight: 600;
	font-family: $app-font !important;
	color: $app-font-color;
	transition: background-color 200ms, color 200ms;

	&:focus {
		outline: none;
	}

	&:disabled {
		cursor: initial;
	}

	&.big-btn {
		padding-left: 40px;
		padding-right: 40px;

		.mat-icon, [class^="icon-"], [class*=" icon-"] {
			font-size: 14px;
		}
	}

	&.color-btn {
		color: $app-white;
		background-color: $app-yellow;
		border: 1px solid $app-yellow-dark;
		border-radius: 3px;
		min-height: 37px;

		&:focus:not(:disabled), &:hover:not(:disabled) {
			background-color: $app-yellow-dark;
		}

		&:disabled {
			color: rgba($app-grey, .7);
			border-color: rgba($app-grey, .7);
			background-color: $app-grey-light;
		}

		&.red {
			background-color: $app-red-2;
			border: 1px solid $app-red-2;

			&:focus:not(:disabled), &:hover:not(:disabled) {
				background-color: $app-red-2;
			}
		}

		&.white {
			background-color: $app-white;
			border: 1px solid $app-grey-2;
			color: $app-black;

			&:focus:not(:disabled), &:hover:not(:disabled) {
				background-color: $app-grey-2;
			}
		}
	}

	&.text-btn {
		color: $app-grey;
		background-color: transparent;
		border: none;

		&:focus:not(:disabled), &:hover:not(:disabled) {
			color: $app-blue-dark-2;
		}

		&:disabled {
			color: rgba($app-grey, .7);
		}
	}

	&.link-btn,
	&.btn-icon {
		background-color: transparent;
		border: none;
		color: $app-yellow;

		&:focus:not(:disabled), &:hover:not(:disabled) {
			color: $app-yellow-dark;
		}

		&.blue {
			color: $app-blue;

			&:focus:not(:disabled), &:hover:not(:disabled) {
				color: $app-blue-dark;
			}
		}

		&.blue-dark {
			color: $app-blue-dark-2;

			&:focus:not(:disabled), &:hover:not(:disabled) {
				color: $app-blue-dark;
			}
		}

		&.grey {
			color: $app-grey;

			&:focus:not(:disabled), &:hover:not(:disabled) {
				color: $app-black;
			}
		}

		&.grey-2 {
			color: $app-grey-2;

			&:focus:not(:disabled), &:hover:not(:disabled) {
				color: $app-grey;
			}
		}

		&.red {
			color: $app-red-2;

			&:focus:not(:disabled), &:hover:not(:disabled) {
				color: darken($app-red-2, 20%);
			}
		}

		&:disabled {
			color: rgba($app-grey, .7) !important;
		}
	}

	&.link-btn {
		padding: 0;

		* {
			vertical-align: middle;
		}
	}

	&.btn-icon {
		padding: .25rem;

		span {
			vertical-align: middle;
		}

		.mat-icon, [class^="icon-"], [class*=" icon-"] {
			font-size: 16px;
			height: 16px;
			width: 16px;
		}
	}
}

.mat-mdc-checkbox .mdc-form-field  .mdc-checkbox {
	//padding: 0 6px 0 0;
	padding: 0;
	margin: 0;
	height: 1rem;
	width: 1rem;
	flex: auto;
	--mdc-checkbox-state-layer-size: 1rem;

	.mat-mdc-checkbox-touch-target {
		height: 1rem;
		width: 1rem;
	}

	.mdc-checkbox__background {
		height: 1rem;
		width: 1rem;
		background-color: $app-white;
	}
}


