// Génération des classes colors pour chaque couleur
@each $color, $value in $colors {
	.color-#{$color} {
		color: $value !important;
	}

	.bg-#{$color} {
		background-color: $value !important;
	}
	a.bg-#{$color},
	button.bg#{$color} {
		@include hover-focus() {
			background-color: darken($value, 10%) !important;
		}
	}
}
