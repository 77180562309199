.filter-bar {
	float: right;
}

.header-link-active {
	color: $app-white;
	font-weight: 600;
}

.header-bar {
	app-back-button {
		height: 100%;

		* {
			vertical-align: middle;
		}
	}
}
