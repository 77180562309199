h1, h2, h3, h4 {
	display: block;
}

h1 {
	color: $app-blue;
	font-size: 20px;
	font-weight: 800;
}

h2 {
	color: $app-blue-dark-2;
	font-size: 16px;
	font-weight: 800;
}

h3 {
	color: $app-blue-dark-2;
	font-size: 14px;
	font-weight: 600;
}

p {
	color: $app-blue-dark;
	font-size: 15px;
	font-weight: 400;
	margin: 6px 0;
}

caption {
	display: none;
}

.label {
	color: $app-blue-dark;
	font-size: 12px;
	font-weight: 400;
}

.light {
	color: $app-grey;
	font-size: 10px;
	font-weight: 400;
}

label, mat-label {
	color: $app-blue-dark-2;
	font-size: 10px;
	font-weight: 700;
}


.small-label {
	color: $app-blue-dark;
	font-size: 8px;
	text-transform: uppercase;
	font-weight: 400;
}

a {
	color: $app-blue-dark;
}

a:active {
	color: $app-blue;
}

textarea {
	font-family: $app-font !important;
}

@media print {
	h1, h2, h3, h4, h5, p, th, td, div {
		color: black;
	}

	h1 {
		font-size: 16px !important;
	}

	h2 {
		font-size: 14px !important;
	}

	h3 {
		font-size: 12px !important;
	}

	p, th, td, div {
		font-size: 12px !important;
	}

}
.mdc-tooltip--multiline .mdc-tooltip__surface {
	font-size: 10px;
}
