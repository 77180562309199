.mat-tree-node {
	min-height: 24px;

	&.site {
		font-weight: bold;
		font-size: 1rem;
	}

	&.zone {
		font-style: italic;
	}
}
.mat-nested-tree-node {
	.mat-mdc-icon-button, .mat-mdc-icon-button .mat-mdc-button-touch-target {
		width: 24px;
		height: 24px;
		line-height: 24px;
		padding: 0;
	}
}

