.lg-dialog {
	position: absolute;
	top: 10px;
	left: 10px;
	min-height: 2em;
	background-color: $app-grey-border;
	border: 1px solid $app-grey-2;
	z-index: 10;
	min-width: 100px;


	.lg-dialog-header {
		display: flex;
		padding: 5px;
		border-bottom: 1px solid $app-grey-2;

		span {
			flex-grow: 1;
			font-size: 1.2rem;
			font-weight: bold;
			color: $app-blue;
		}

		.btn-icon {
			flex-grow: 0;
		}
	}

	.lg-dialog-content {
		padding: 5px;

		.lg-dialog-row {
			display: flex;
			align-items: center;
		}

		span {
			font-weight: bold;
		}

		input, select {
			margin: 5px;
			min-width: 60px;
			min-height: 1.5rem;
			outline: none;
			width: 100%;
		}
	}

	.lg-dialog-footer {
		border-top: 1px solid $app-grey-2;
		padding: 5px;
		display: flex;
		justify-content: flex-end;
	}

	button {
		margin: 5px;
		min-height: 20px;
		padding: 5px 10px;

		&.link-btn {
			background: $app-white;
		}
	}
}
