@import "bootstrap/scss/mixins/hover";

$app-black: #0B1022;
$app-white: #FFFFFF;
$app-black-opacity: #0000006b;

$app-blue-light: #D7E8F6;
$app-blue: #137FC8;
$app-blue-opacity: rgba($app-blue, .2);
$app-blue-dark: #225470;
$app-blue-dark-2: #153247;

$app-yellow: #FFB43F;
$app-yellow-opacity: rgba($app-yellow, .2);
$app-yellow-dark: #DB901B;

$app-green: #13C897;
$app-green-border: #09A87D;
$app-green-opacity: rgba($app-green, .2);

$app-grey: #727789;
$app-grey-2: #D3D5DB;
$app-grey-3: #EDEEF2;
$app-grey-border: #EAECF0;
$app-grey-light: #F5F7FC;


$app-red: #FC7B8A;
$app-red-2: #F03B50;
$app-red-opacity: rgba($app-red, .2);

// Map des couleurs
$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
		(
			"white": $app-white,
			"black": $app-black,
			"grey": $app-grey,
			"grey-2": $app-grey-2,
			"grey-light": $app-grey-light,
			"grey-border": $app-grey-border,
			"blue": $app-blue,
			"blue-dark": $app-blue-dark,
			"blue-dark-2": $app-blue-dark-2,
			"green": $app-green,
			"yellow": $app-yellow,
			"yellow-opacity": $app-yellow-opacity,
			"orange": $app-yellow-dark,
			"red": $app-red
		),
		$colors
);


// Material design palette for Angular Material
// http://mcg.mbitson.com/#!?primary=%23005db9&warn=%23bc003a&themename=digisco
$md-primary: (
	50 : $app-blue,
	100 : $app-blue,
	200 : $app-blue,
	300 : $app-blue,
	400 : $app-blue,
	500 : $app-blue,
	600 : $app-blue-dark,
	700 : $app-blue-dark,
	800 : $app-blue-dark-2,
	900 : $app-blue-dark-2,
	A100 : $app-blue,
	A200 : $app-blue,
	A400 : $app-blue,
	A700 : $app-blue,
	contrast: (
		50 : $app-black,
		100 : $app-black,
		200 : $app-black,
		300 : $app-black,
		400 : $app-white,
		500 : $app-white,
		600 : $app-white,
		700 : $app-white,
		800 : $app-white,
		900 : $app-white,
		A100 : $app-black,
		A200 : $app-black,
		A400 : $app-black,
		A700 : $app-white,
	)
);

$md-warn: (
	50 : #f7e0e7,
	100 : #ebb3c4,
	200 : $app-red,
	300 : $app-red,
	400 : $app-red,
	500 : $app-red,
	600 : #b60034,
	700 : #ad002c,
	800 : #a50025,
	900 : #970018,
	A100 : $app-red,
	A200 : $app-red,
	A400 : $app-red,
	A700 : $app-red,
	contrast: (
		50 : $app-black,
		100 : $app-black,
		200 : $app-black,
		300 : $app-white,
		400 : $app-white,
		500 : $app-white,
		600 : $app-white,
		700 : $app-white,
		800 : $app-white,
		900 : $app-white,
		A100 : $app-black,
		A200 : $app-black,
		A400 : $app-black,
		A700 : $app-white,
	)
);

