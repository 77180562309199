.mat-mdc-chip-grid .mat-mdc-chip {
	border-radius: 3px;
	border: 1px solid $app-blue-dark;
	font-size: 12px;
	padding: 2px 8px;
	--mdc-chip-label-text-color: #{$app-white};
	--mdc-chip-elevated-container-color: #{$app-blue};
	--mdc-chip-with-trailing-icon-trailing-icon-color: #{$app-grey-light};
	--mdc-chip-container-height: 1.5rem;

	&.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
		padding-left: 0;
	}

	.mat-mdc-chip-remove {
		color: $app-grey-light;
		width: 1rem;
		height: 1rem;
		font-size: 1rem;
	}
}

.input-chip {
	input.mat-mdc-chip-input {
		margin-top: .5rem;
		margin-bottom: 0;
	}
}
