.input-date, .input-date-range {

	mat-datepicker-toggle {
		height: 22px;
		vertical-align: middle;
		display: inline-block;

			.mat-mdc-icon-button {
			width: 22px;
			height: 22px;
			line-height: 20px;
			padding: 0;

			svg {
				width: 22px;
				height: 22px;
			}
		}
	}

	.mat-mdc-form-field-infix > input, .mat-mdc-form-field-infix > mat-date-range-input {
		padding-left: 5px;
		width: calc(100% - 27px); // 5px padding + 22px icon = 27 from width
		display: inline-block;
	}

	.mat-mdc-form-field-label-wrapper .mat-form-field-label {
		padding-left: 35px;
	}
}

.input-date {
	width: 9rem;
}

.mat-form-field.mat-form-field-type-mat-date-range-input.mat-form-field-disabled .mat-form-field-infix,
.input-date.mat-form-field.mat-form-field-type-mat-input.mat-form-field-disabled .mat-form-field-infix {
	background-color: white !important;
}

.mat-date-range-input-wrapper {
	width: 100%;
}
