/* You can add global styles to this file, and also import other style files */
// Bootstrap
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/grid";
// Material icons
@import "material-design-icons/iconfont/material-icons.css";
// Angular material (custom)
@import "assets/css/variable/angular-material-theme";
// QuillJS
@import "quill/dist/quill.snow.css";
// Litegraph
@import "@211-feelink/litegraph/assets/litegraph/litegraph-lib.css";

// Fonts
@import "assets/fonts/open-sans/style";
@import "assets/fonts/pandora/style";

// Project
@import "assets/css/variable/variables";
@import "assets/css/style/styles";

