@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?paflj5');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?paflj5#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?paflj5') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?paflj5') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?paflj5##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-add_info {
  &:before {
    content: $icon-add_info; 
  }
}
.icon-admin {
  &:before {
    content: $icon-admin; 
  }
}
.icon-admin_add {
  &:before {
    content: $icon-admin_add; 
  }
}
.icon-arrow_down {
  &:before {
    content: $icon-arrow_down; 
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left; 
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right; 
  }
}
.icon-arrow_up {
  &:before {
    content: $icon-arrow_up; 
  }
}
.icon-articles {
  &:before {
    content: $icon-articles; 
  }
}
.icon-articles_2 {
  &:before {
    content: $icon-articles_2; 
  }
}
.icon-articles_add {
  &:before {
    content: $icon-articles_add; 
  }
}
.icon-back {
  &:before {
    content: $icon-back; 
  }
}
.icon-cached {
  &:before {
    content: $icon-cached; 
  }
}
.icon-clients {
  &:before {
    content: $icon-clients; 
  }
}
.icon-clients_add {
  &:before {
    content: $icon-clients_add; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-code_off {
  &:before {
    content: $icon-code_off; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-credit_card {
  &:before {
    content: $icon-credit_card; 
  }
}
.icon-credit_card_off {
  &:before {
    content: $icon-credit_card_off; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-deya {
  &:before {
    content: $icon-deya; 
  }
}
.icon-done {
  &:before {
    content: $icon-done; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-drag_handle {
  &:before {
    content: $icon-drag_handle; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-exchange {
  &:before {
    content: $icon-exchange; 
  }
}
.icon-existing {
  &:before {
    content: $icon-existing; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-finish {
  &:before {
    content: $icon-finish; 
  }
}
.icon-fullscreen {
  &:before {
    content: $icon-fullscreen; 
  }
}
.icon-global {
  &:before {
    content: $icon-global; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-help_o {
  &:before {
    content: $icon-help_o; 
  }
}
.icon-historic {
  &:before {
    content: $icon-historic; 
  }
}
.icon-import_export {
  &:before {
    content: $icon-import_export; 
  }
}
.icon-in_progress {
  &:before {
    content: $icon-in_progress; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-input {
  &:before {
    content: $icon-input; 
  }
}
.icon-layers {
  &:before {
    content: $icon-layers; 
  }
}
.icon-layers-o {
  &:before {
    content: $icon-layers-o; 
  }
}
.icon-layers_clear {
  &:before {
    content: $icon-layers_clear; 
  }
}
.icon-layers_clear-o {
  &:before {
    content: $icon-layers_clear-o; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-link_off {
  &:before {
    content: $icon-link_off; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-lock_open {
  &:before {
    content: $icon-lock_open; 
  }
}
.icon-maintenance {
  &:before {
    content: $icon-maintenance; 
  }
}
.icon-maximize {
  &:before {
    content: $icon-maximize; 
  }
}
.icon-merge {
  &:before {
    content: $icon-merge; 
  }
}
.icon-minimize {
  &:before {
    content: $icon-minimize; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-one {
  &:before {
    content: $icon-one; 
  }
}
.icon-option {
  &:before {
    content: $icon-option; 
  }
}
.icon-output {
  &:before {
    content: $icon-output; 
  }
}
.icon-pageview {
  &:before {
    content: $icon-pageview; 
  }
}
.icon-picture {
  &:before {
    content: $icon-picture; 
  }
}
.icon-power {
  &:before {
    content: $icon-power; 
  }
}
.icon-preview {
  &:before {
    content: $icon-preview; 
  }
}
.icon-project {
  &:before {
    content: $icon-project; 
  }
}
.icon-project_add {
  &:before {
    content: $icon-project_add; 
  }
}
.icon-published_with_changes {
  &:before {
    content: $icon-published_with_changes; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-report {
  &:before {
    content: $icon-report; 
  }
}
.icon-report-stat {
  &:before {
    content: $icon-report-stat; 
  }
}
.icon-review {
  &:before {
    content: $icon-review; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-server {
  &:before {
    content: $icon-server; 
  }
}
.icon-server-outline {
  &:before {
    content: $icon-server-outline; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-storage {
  &:before {
    content: $icon-storage; 
  }
}
.icon-syno {
  &:before {
    content: $icon-syno; 
  }
}
.icon-tag {
  &:before {
    content: $icon-tag; 
  }
}
.icon-to_do_list {
  &:before {
    content: $icon-to_do_list; 
  }
}
.icon-trophy {
  &:before {
    content: $icon-trophy; 
  }
}
.icon-undo {
  &:before {
    content: $icon-undo; 
  }
}
.icon-verified {
  &:before {
    content: $icon-verified; 
  }
}
.icon-view_list {
  &:before {
    content: $icon-view_list; 
  }
}
.icon-view_module {
  &:before {
    content: $icon-view_module; 
  }
}
.icon-visibility {
  &:before {
    content: $icon-visibility; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-zoom_in {
  &:before {
    content: $icon-zoom_in; 
  }
}
.icon-zoom_out {
  &:before {
    content: $icon-zoom_out; 
  }
}

