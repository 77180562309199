.mat-menu-panel {
	border-radius: 0 !important;

	.mat-menu-content:not(:empty) {
		padding: 0;
	}
}

.full-width.mat-menu-panel {
	max-width: 100%;
}
