@import "src/assets/css/variable/variables";

html, body {
	height: 100%;
}

body {
	margin: 0;
	font-family: $app-font !important;
	color: $app-font-color !important;
	font-size: 15px;
	font-weight: 400;
}

h1 {
	margin: .7rem 0;
}

* {
	box-sizing: border-box;
}

::selection {
	background: $app-blue; /* WebKit/Blink Browsers */
	color: $app-white;
}

::-moz-selection {
	background: $app-blue; /* Gecko Browsers */
	color: $app-white;
}

.required::after {
	content: ' *';
}

.content {
	padding: 0 30px;
}

.clickable, .cursor-pointer {
	cursor: pointer;
}

.no-wrap {
	white-space: pre-line;
}
