.left-menu-btn {
	width: 100%;
	background: transparent;
	border: 1px solid $app-grey-border;
	border-left: none;
	border-right: none;
	min-height: 45px;
	padding-left: 25px;
	padding-right: 25px;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	transition: background-color 200ms;

	&:not(:last-child) {
		border-bottom: none;
	}

	&.active, &:focus {
		background: $app-white;
		color: $app-blue;
	}

	&:hover {
		background: $app-white;
	}

	> * {
		vertical-align: middle;
		line-height: 22px;
	}

	> [class^="icon-"]:not(button), > [class*=" icon-"]:not(button) {
		font-size: 20px;
		padding-right: 10px;
	}

	.btn-icon {
		float: right;
		padding: 0;
		font-size: 16px;
	}

	.count {
		padding-left: 5px;
		color: rgba($app-blue-dark-2, .35);
	}
}

h2.left-menu-subtitle {
	padding: 20px 25px 10px;

	> [class^="icon-"]:first-child:not(button), > [class*=" icon-"]:first-child:not(button) {
		font-size: 20px;
		padding-right: 10px;
	}

	button.btn-icon {
		float: right;
	}

	* {
		vertical-align: middle;
	}
}

.main-content {
	min-height: 100%;
	padding: 20px 20px 20px 25px;
	border-left: 2px solid $app-grey-border;
}
