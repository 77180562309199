body {
	.sticky-top {
		.ck.ck-editor {
			height: 100%;
			display: flex;
			flex-direction: column;

			.ck-editor__top {
				position: sticky;
				top: -.5rem;
			}

			.ck-editor__main {
				flex-grow: 1;

				.ck-content {
					height: 100%;
				}
			}
		}
	}
}
