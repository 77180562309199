.mat-mdc-snack-bar-container {
	font-family: $app-font;
	color: $app-white;
	border-radius: 0 !important;
	min-height: 20px !important;

	&.snack-info .mdc-snackbar__surface {
		background: $app-blue !important;
		border: 1px solid darken($app-blue, 15%);

		.mat-mdc-button.mat-mdc-snack-bar-action {
			background: darken($app-blue, 15%);
		}
	}

	&.snack-warning .mdc-snackbar__surface {
		background: $app-yellow !important;
		border: 1px solid darken($app-yellow, 15%);

		.mat-mdc-button.mat-mdc-snack-bar-action {
			background: darken($app-yellow, 15%);
		}
	}

	&.snack-error .mdc-snackbar__surface {
		background: $app-red !important;
		border: 1px solid darken($app-red, 15%);

		.mat-mdc-button.mat-mdc-snack-bar-action {
			background: darken($app-red, 15%);
		}
	}

	&.snack-success .mdc-snackbar__surface {
		background: $app-green !important;
		border: 1px solid darken($app-green, 15%);

		.mat-mdc-button.mat-mdc-snack-bar-action {
			background: darken($app-green, 15%);
		}
	}

	.mat-mdc-simple-snack-bar {
		white-space: pre-line;
	}

	.mdc-snackbar__surface .mat-mdc-button.mat-mdc-snack-bar-action {
		color: $app-white !important;
		max-height: 30px !important;
		line-height: 30px !important;
		border-radius: 0 !important;
		font-size: 12px;
	}
}
