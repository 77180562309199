.cdk-overlay-backdrop {
	background-color: $backdrop-bg;
}

mat-dialog-container.mat-mdc-dialog-container {
	border-radius: 0;
	padding: 0;
	position: relative;
	max-width: none;

	.mat-mdc-dialog-title {
		padding: 12px 24px;
		border-bottom: 1px solid $app-grey-border;
		display: flex;
		align-items: center;
		margin-bottom: 18px;

		h1 {
			margin: 0;
			display: inline-block;
		}

		*:not([class^="icon-"]):not([class*=" icon-"]) {
			flex-grow: 1;
		}

		button {
			background-color: transparent;
			border: none;
			color: $app-grey;
		}
	}

	.mat-mdc-dialog-content {
		padding: 24px;
		margin: 0 18px;

		background-color: $app-grey-light;
		border: 1px solid $app-grey-border;

		color: #000000de;
		font-size: 15px;

		&.no-padding {
			padding: 0;
		}
	}
	.mat-mdc-text-field-wrapper{
		padding: 0 !important;
	}

	.mat-mdc-dialog-actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 12px 24px;
		box-sizing: border-box;
	}
}

