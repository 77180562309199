.ql-container.ql-snow {
	border-color: rgba($app-blue-dark-2, .5);
	background: $app-white;

	* {
		color: $app-black;
	}
}

.ql-toolbar.ql-snow {
	border-color: rgba($app-blue-dark-2, .5);
	border-top: none;
	background: $app-grey-border;
	padding: 5px;
}

quill-editor {
	display: block !important;
}

.quill-form-field {
	margin-bottom: 1rem;

	mat-error.mat-error {
		font-size: 10px;
		text-align: right;
	}
}
