mat-spinner {
	margin: auto;
}


.loader-container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba($app-grey, .5);
	z-index: 100000;

	.loader {
		width: 200px;
		height: 200px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		//background: $app-white;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
