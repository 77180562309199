@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url('/assets/fonts/open-sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}
