.mat-expansion-panel-body {
	padding: 0 !important;
}

mat-expansion-panel.mat-expansion-panel {
	background: transparent;
	border-top: 1px solid $app-grey-border;
	border-bottom: 1px solid $app-grey-border;
	padding: 0;
	border-radius: 0 !important;

	&:not([class*=mat-elevation-z]) {
		box-shadow: none;
	}
}

mat-expansion-panel-header {
	border-top: 1px solid $app-grey-border;
	border-bottom: 1px solid $app-grey-border;
}

.mat-expansion-panel-spacing {
	margin: 0 !important;
}
