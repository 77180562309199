@import "src/assets/css/variable/variables";

body {
	input[type=search]::-ms-reveal, input[type=search]::-ms-clear {
		display: none; width : 0; height: 0;
	}

	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		display: none;
	}

	.mdc-line-ripple {
		display: none;
	}

	.mat-mdc-form-field-infix {
		border: 1px solid rgba(21, 50, 71, .5);
		margin-top: 1rem;
		padding: 0.25rem 0.25rem 0.25rem 0.5rem !important;
		background: white;
		min-height: 20px;
		width: 250px;
	}

	.mat-mdc-form-field-flex .mat-mdc-form-field-infix {
		width: 250px;
	}

	.mat-mdc-form-field-type-mat-date-range-input.input-date-range .mat-mdc-form-field-infix {
		width: 250px;

		.mat-date-range-input-inner[disabled] {
			color: $app-grey;
		}
	}

	.mat-mdc-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled),
	.mat-mdc-form-field-focus-overlay,
	.mdc-text-field--filled.mdc-text-field--disabled {
		background-color: transparent;
	}

	.mat-form-field-disabled .mat-mdc-form-field-infix {
		border-style: dashed;
	}

	label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
		transform: translateY(-20px) translateZ(0.001px);
		font-size: 10px;
		font-family: $app-font;
		width: 100%;
		padding: 0.25rem 0.25rem 0.25rem 0;
		line-height: 16px;
		transition: none;
		left: 0;
		top: 0;
	}

	.mat-mdc-form-field-icon-suffix {
		padding: 0 !important;
	}

	.mat-mdc-text-field-wrapper {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.no-padding-bottom .mat-mdc-form-field-subscript-wrapper {
		display: none;
	}

	mat-form-field.input-number {
		width: 5rem;
	}

	div.mat-mdc-form-field-icon-suffix {
		align-self: baseline;
	}

	.mat-mdc-select-placeholder {
		color: $app-black-opacity;
	}

	.mat-select {
		font-family: $app-font;
	}

	.mat-select-panel {
		.mat-option.mat-active,
		.mat-option:hover:not(.mat-option-disabled),
		.mat-option:focus:not(.mat-option-disabled) {
			background: $app-blue-opacity;
		}

		.mat-option.mat-selected:not(.mat-option-multiple) {
			background: $app-blue-opacity;
		}
	}

	.mat-mdc-form-field-infix input.mat-mdc-input-element,
	.mat-form-field.mat-form-field-type-mat-select .mat-select-value {
		color: $app-blue-dark;
		font-size: 15px;
	}

	.mat-form-field-label-wrapper {
		top: -1rem;
		padding-top: 1rem;

		.mat-form-field-label {
			color: $app-blue-dark-2;
			padding: 0.25rem 0.25rem 0.25rem 0.5rem;
			line-height: 16px;
		}
	}

	.mat-form-field-should-float .mat-form-field-label-wrapper .mat-form-field-label {
		padding: 0.25rem 0.25rem 0.25rem 0;
	}

	.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
		padding-left: 0;
		padding-right: 0;
	}

	.mat-mdc-select-arrow-wrapper {
		height: 16px;
		transform: none !important;

		.mat-mdc-select-arrow {
			color: $app-blue-dark-2;
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid;
			margin: 0 4px;

			svg {
				display: none;
			}
		}
	}

	.mat-mdc-select-panel-above div.mdc-menu-surface.mat-mdc-select-panel {
		border-radius: 4px;
		padding: 0;
	}

	.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel {
		min-width: calc(100% + 64px);
		box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
		padding-top: 0;
		padding-bottom: 0;
		max-height: 256px;
		border-radius: 4px;
		outline: 0;
		background: white;

		.mat-mdc-option.mdc-list-item.mat-mdc-option-multiple:hover, .mat-mdc-option-active {
			background: $app-blue-opacity;
		}

		.mat-mdc-option.mdc-list-item.mdc-list-item--selected {
			color: $app-blue
		}
	}

	.mat-mdc-checkbox label,
	.mat-mdc-radio-button label {
		color: $app-blue-dark-2;
		font-size: 14px;
		font-weight: 400;
		font-family: $app-font;
		white-space: normal;
	}

	mat-radio-button.mat-mdc-radio-button .mdc-radio {
		padding: 0;
	}


	.mat-radio-outer-circle {
		background: $app-white;
	}


	.mat-form-field.input-number {
		width: 5rem;
	}

	.no-margin-top .mat-mdc-form-field-infix {
		margin-top: 0 !important;
	}

	.mat-checkbox-inner-container {
		background: $app-white;
	}

	.button-filter {
		mat-select .mat-select-value {
			overflow: unset !important;
			text-overflow: unset !important;
			white-space: unset !important;
			display: inline !important;
		}
	}
}
