@import "src/assets/css/variable/variables";

.mat-slide-toggle {
	.mat-slide-toggle-thumb {
		background-color: $app-white;
		border: 2px solid $app-grey;
	}

	.mat-slide-toggle-bar {
		background-color: $app-grey;
	}

	&.mat-checked {
		.mat-slide-toggle-thumb {
			background-color: $app-white;
			border: 2px solid $app-green;
		}

		.mat-slide-toggle-bar {
			background-color: $app-green;
		}
	}
}
