table.mat-mdc-table {
	border-spacing: 0 3px !important;
	font-family: $app-font;

	tr.mat-mdc-header-row {
		height: 30px;
	}

	th.mat-mdc-header-cell {
		color: $app-blue-dark;
		font-size: 12px;
		border: none;
		font-weight: 500;
	}

	tr.mat-mdc-row td {
		background-color: $app-grey-light;
		border-top: 1px solid $app-grey-border;
		border-bottom: 1px solid $app-grey-border;
		color: $app-blue-dark;
		font-size: 14px;

		&:first-child {
			border-left: 1px solid $app-grey-border;
			padding-left: 15px;
		}

		&:last-child {
			border-right: 1px solid $app-grey-border;
			padding-right: 15px;
		}
	}

}
